/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { Permission } from '~/src/Domain/Identity/Permission'
import type { Role } from '~/src/Domain/Identity/Role'
import UserIri from '~/src/Domain/Identity/UserIri'
import BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'
import Slug from '~/src/Domain/Shared/Identifier/Slug'

export interface UserProps extends DomainModelProps {
  '@id': string
  '@type': string
  active: boolean
  avatar: string | undefined
  created: string
  email: string
  firstName: string
  lastName: string
  permissions: Permission[]
  roles: Role[]
  locale: string
}

export default class User implements DomainModelInterface<User, UserProps> {
  public readonly '@id': UserIri
  public readonly '@type': string

  private constructor(
    id: UserIri,
    type: string,
    public readonly active: boolean,
    public readonly avatar: string | undefined,
    public readonly created: BetterDateTime,
    public readonly email: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly permissions: Permission[],
    public readonly roles: Role[],
    public readonly locale: string,
  ) {
    this['@id'] = id
    this['@type'] = type
  }

  public getSlug(): Slug {
    return Slug.fromString(`${this.firstName}-${this.lastName}`)
  }

  public hasRole(role: Role): boolean {
    return this.roles.includes(role)
  }

  public hasPermission(permission: Permission): boolean {
    return this.permissions.includes(permission)
  }

  public isAdmin(): boolean {
    return this.hasPermission(Permission.ADMIN)
  }

  public getInitials(): string {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`
  }

  public getAddress(): string {
    return `${this.firstName} ${this.lastName} <${this.email}>`
  }

  public getClassName(): string {
    return 'User'
  }

  public fromJSON(values: UserProps): User {
    return new User(
      new UserIri(values['@id']),
      values['@type'],
      values.active,
      values.avatar,
      new BetterDateTime(values.created),
      values.email,
      values.firstName,
      values.lastName,
      values.permissions,
      values.roles,
      values.locale,
    )
  }

  public toJSON(): UserProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      active: this.active,
      avatar: this.avatar,
      created: this.created.toString(),
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      permissions: this.permissions,
      roles: this.roles,
      locale: this.locale,
    }
  }
}
