/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
export enum DeliveryStatus {
  INITIAL = 'initial',
  IN_PROGRESS = 'in_progress',
  WAITING_ON_EXTERNAL_CLIENT = 'waiting_on_external_client',
  WAITING_ON_AUDIT_TEAM = 'waiting_on_audit_team',
  WAITING_ON_INTERNAL = 'waiting_on_internal',
  DELIVERED = 'delivered',
  ABANDONED = 'abandoned',
}
