/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type AuditTeamMemberIri from '~/src/Domain/Engagement/AuditTeamMemberIri'
import type { BankAccountProps } from '~/src/Domain/Engagement/BankAccount'
import type BankAccountIri from '~/src/Domain/Engagement/BankAccountIri'
import type { BusinessUnitProps } from '~/src/Domain/Engagement/BusinessUnit'
import type BusinessUnitIri from '~/src/Domain/Engagement/BusinessUnitIri'
import Engagement, { type EngagementProps } from '~/src/Domain/Engagement/Engagement'
import type EngagementApiRepositoryInterface from '~/src/Domain/Engagement/EngagementApiRepositoryInterface'
import type EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import type { FiscalYearProps } from '~/src/Domain/Engagement/FiscalYear'
import type FiscalYearIri from '~/src/Domain/Engagement/FiscalYearIri'
import type ParameterName from '~/src/Domain/Engagement/Parameter/ParameterName'
import type ParameterIri from '~/src/Domain/Engagement/ParameterIri'
import type { PhaseProps } from '~/src/Domain/Engagement/Phase'
import PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type { PhaseType } from '~/src/Domain/Engagement/PhaseType'
import type UserIri from '~/src/Domain/Identity/UserIri'
import type OrganisationIri from '~/src/Domain/Organisation/OrganisationIri'
import HydraCollectionResponse, {
  type HydraCollectionResponseArgs,
} from '~/src/Domain/Shared/Http/HydraCollectionResponse'
import type { Services } from '~/src/Infrastructure/Shared/Container/Container'
import type HttpClient from '~/src/Infrastructure/Shared/Http/HttpClient'

export interface CreateEngagementPayload {
  type: string
  name: string
  description: string
  rollForwardedFrom: string | undefined
  internalClientCode: string | undefined
  internalEngagementCode: string | undefined
  businessUnits: Omit<BusinessUnitProps, '@id' | '@type'>[]
  bankAccounts: Omit<BankAccountProps, '@id' | '@type'>[]
  fiscalYears: Omit<FiscalYearProps, '@id' | '@type'>[]
  auditTeamManager: string
  contactPerson: string
  auditTeamMembers: string[]
  externalClientContactPersonName: string
  externalClientContactPersonEmail: string
  externalClientContactPersonPreferredLanguage: string
  externalClientContactPersonOnboardingDate: string
  signOffDate: string
  phases: Omit<PhaseProps, '@id' | '@type'>[]
  token: string
}

export default class EngagementApiRepository implements EngagementApiRepositoryInterface {
  private readonly httpClient: HttpClient

  public constructor({ httpClient }: Services) {
    this.httpClient = httpClient
  }

  public async create(organisation: OrganisationIri, payload: CreateEngagementPayload): Promise<void> {
    await this.httpClient.post(`${organisation.toString()}/engagements`, payload)
  }

  public async update(engagement: EngagementIri, payload: Record<string, any>): Promise<void> {
    await this.httpClient.patch(`${engagement.toString()}`, payload)
  }

  public async archive(engagement: EngagementIri): Promise<void> {
    await this.httpClient.patch(`${engagement.toString()}/archive`)
  }

  public async activate(phase: PhaseIri): Promise<void> {
    return this.httpClient.put(`${phase.toString()}/activate`)
  }

  public async findAll(): Promise<Engagement[]> {
    const response = HydraCollectionResponse.prototype.fromJSON(
      await this.httpClient.get<HydraCollectionResponseArgs<EngagementProps>>('/v1/engagements?pagination=false'),
      Engagement.prototype.fromJSON,
    )

    return response.member
  }

  public async findForOrganisation(organisation: OrganisationIri): Promise<Engagement[]> {
    const url = new URL(`${organisation.toString()}/engagements`, this.httpClient.getBaseUrl())
    url.searchParams.append('pagination', 'false')

    const response = HydraCollectionResponse.prototype.fromJSON(
      await this.httpClient.get<HydraCollectionResponseArgs<EngagementProps>>(url.toString()),
      Engagement.prototype.fromJSON,
    )

    return response.member
  }

  public async findActive(user: UserIri): Promise<PhaseIri | undefined> {
    try {
      const response = await this.httpClient.get<{ ['@id']: string }>(`${user.toString()}/phase`)

      return new PhaseIri(response['@id'])
    } catch {
      return undefined
    }
  }

  public async addTeamMembers(
    engagement: EngagementIri,
    payload: { auditTeamMembers: AuditTeamMemberIri[] },
  ): Promise<void> {
    await this.httpClient.post(`${engagement.toString()}/audit_team_members`, { auditTeamMembers: payload.auditTeamMembers.map((a) => a.toString()) })
  }

  public async removeAuditTeamMember(auditTeamMember: AuditTeamMemberIri): Promise<void> {
    await this.httpClient.delete(auditTeamMember.toString())
  }

  public async addBusinessUnit(
    engagement: EngagementIri,
    payload: Omit<BusinessUnitProps, '@id' | '@type'>,
  ): Promise<void> {
    await this.httpClient.post(`${engagement.toString()}/business_units`, payload)
  }

  public async changeBusinessUnit(
    businessUnit: BusinessUnitIri,
    payload: Omit<BusinessUnitProps, '@id' | '@type'>,
  ): Promise<void> {
    await this.httpClient.patch(businessUnit.toString(), payload)
  }

  public async removeBusinessUnit(businessUnit: BusinessUnitIri): Promise<void> {
    await this.httpClient.delete(businessUnit.toString())
  }

  public async syncBusinessUnits(engagement: EngagementIri): Promise<void> {
    await this.httpClient.post(`${engagement.toString()}/business_units/sync`)
  }

  public async addBankAccount(
    engagement: EngagementIri,
    payload: Omit<BankAccountProps, '@id' | '@type'>,
  ): Promise<void> {
    await this.httpClient.post(`${engagement.toString()}/bank_accounts`, payload)
  }

  public async changeBankAccount(
    bankAccount: BankAccountIri,
    payload: Omit<BankAccountProps, '@id' | '@type'>,
  ): Promise<void> {
    await this.httpClient.patch(bankAccount.toString(), payload)
  }

  public async deleteBankAccount(bankAccount: BankAccountIri): Promise<void> {
    await this.httpClient.delete(bankAccount.toString())
  }

  public async syncBankAccounts(engagement: EngagementIri): Promise<void> {
    await this.httpClient.post(`${engagement.toString()}/bank_accounts/sync`)
  }

  public async addFiscalYear(
    engagement: EngagementIri,
    payload: Omit<FiscalYearProps, '@id' | '@type'>,
  ): Promise<void> {
    await this.httpClient.post(`${engagement.toString()}/fiscal_years`, payload)
  }

  public async changeFiscalYear(
    fiscalYear: FiscalYearIri,
    payload: Omit<FiscalYearProps, '@id' | '@type'>,
  ): Promise<void> {
    await this.httpClient.patch(fiscalYear.toString(), payload)
  }

  public async deleteFiscalYear(fiscalYear: FiscalYearIri): Promise<void> {
    await this.httpClient.delete(fiscalYear.toString())
  }

  public async syncFiscalYears(engagement: EngagementIri): Promise<void> {
    await this.httpClient.post(`${engagement.toString()}/fiscal_years/sync`)
  }

  public async addPhase(
    engagement: EngagementIri,
    payload: {
      type: PhaseType
      description: string
      startDate: string
      endDate: string
      booksClosed: string
    },
  ): Promise<void> {
    await this.httpClient.post(`${engagement.toString()}/phases`, payload)
  }

  public async changePhase(
    phase: PhaseIri,
    payload: {
      type?: PhaseType
      description?: string
      startDate?: string
      endDate?: string
      booksClosed?: string
    },
  ): Promise<void> {
    await this.httpClient.patch(phase.toString(), payload)
  }

  public async removePhase(phase: PhaseIri): Promise<void> {
    await this.httpClient.delete(phase.toString())
  }

  public async addParameter(
    phase: PhaseIri,
    name: ParameterName,
    values: Record<string, any>,
  ): Promise<void> {
    await this.httpClient.post(`${phase.toString()}/parameters`, {
      name: name.toString(),
      values,
    })
  }

  public async changeParameter(parameter: ParameterIri, values: Record<string, any>): Promise<void> {
    await this.httpClient.patch(parameter.toString(), {
      values,
    })
  }

  public async deleteParameter(parameter: ParameterIri): Promise<void> {
    await this.httpClient.delete(parameter.toString())
  }
}
