/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { DeliveryStatus } from '~/src/Domain/DataRequest/DeliveryStatus'
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type { PhaseType } from '~/src/Domain/Engagement/PhaseType'
import BetterDate from '~/src/Domain/Shared/BetterDate/BetterDate'
import type {
  DomainModelInterface,
  DomainModelProps,
} from '~/src/Domain/Shared/DomainModelInterface'

export interface PhaseProps extends DomainModelProps {
  '@id': string
  '@type': string
  type: PhaseType
  description: string
  startDate: string
  endDate: string
  booksClosed: string
  analysisTypes?: AnalysisType[]
  deliveryAssignedTo?: string
  deliveryStatus?: DeliveryStatus
  deliveryRemarks?: string
}

export default class Phase implements DomainModelInterface<Phase, PhaseProps> {
  public readonly '@id': PhaseIri
  public readonly '@type': string
  public readonly analysisTypes: AnalysisType[]

  public readonly analysisTypeOrder: Record<AnalysisType, number> = {
    [AnalysisType.BANK]: 5,
    [AnalysisType.BANK_GENERAL_LEDGER]: 100,
    [AnalysisType.ACCOUNTS_PAYABLE]: 10,
    [AnalysisType.ACCOUNTS_RECEIVABLE]: 15,
    [AnalysisType.GENERAL_LEDGER]: 1,
    [AnalysisType.GENERIC]: 100,
    [AnalysisType.PERSONNEL]: 100,
  }

  private constructor(
    id: PhaseIri,
    _type: string,
    public readonly type: PhaseType,
    public readonly description: string,
    public readonly startDate: BetterDate,
    public readonly endDate: BetterDate,
    public readonly booksClosed: BetterDate,
    analysisTypes: AnalysisType[],
    public readonly deliveryAssignedTo: string | undefined,
    public readonly deliveryStatus: DeliveryStatus | undefined,
    public readonly deliveryRemarks: string | undefined,
  ) {
    this['@id'] = id
    this['@type'] = _type

    this.analysisTypes = analysisTypes.sort((a, b) => this.analysisTypeOrder[a] - this.analysisTypeOrder[b])
  }

  public getClassName(): string {
    return 'Phase'
  }

  public hasAnalysisTypes(): boolean {
    return this.analysisTypes.length > 0
  }

  public isActive(now: BetterDate = new BetterDate()) {
    const daysAgoThreshold = 7

    return this.startDate.getTime() <= now.getTime() && (now.getTime() - this.endDate.getTime()) <= (daysAgoThreshold * 24 * 60 * 60 * 1000)
  }

  public isUpcoming(now: BetterDate = new BetterDate()) {
    return this.startDate.getTime() > now.getTime()
  }

  public fromJSON(values: PhaseProps): Phase {
    return new Phase(
      new PhaseIri(values['@id']),
      values['@type'],
      values.type,
      values.description,
      new BetterDate(values.startDate),
      new BetterDate(values.endDate),
      new BetterDate(values.booksClosed),
      values.analysisTypes ?? [],
      values.deliveryAssignedTo,
      values.deliveryStatus ?? DeliveryStatus.INITIAL,
      values.deliveryRemarks,
    )
  }

  public toJSON(): PhaseProps {
    return {
      '@id': this['@id'].toString(),
      '@type': this['@type'],
      type: this.type,
      description: this.description,
      startDate: this.startDate.toString(),
      endDate: this.endDate.toString(),
      booksClosed: this.booksClosed.toString(),
      analysisTypes: this.analysisTypes,
      deliveryAssignedTo: this.deliveryAssignedTo,
      deliveryStatus: this.deliveryStatus,
      deliveryRemarks: this.deliveryRemarks,
    }
  }
}
